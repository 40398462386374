<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />

    <div class="flex xs12">
      <va-collapse withBackground>
        <span slot="header">{{ $t('layout.filters.label') }}</span>
        <template slot="body">
          <filters
            :loading="loading"
            :actions="filterActions"
            @filter="filterData"
            @clear-filters="filterData(null)"
          />
        </template>
      </va-collapse>
    </div>

    <div class="flex xs12">
      <va-card :title="$t('preaching_points.index')">
        <remote-table
          crud-links="preachings"
          controller="PreachingPoints"
          :columns="tableFields"
          :data="tableData"
          :loading="loading"
          :search="searchOptions"
          :queries="serverParams.queries"
          :pagination="pagination"
          @update-data="updateData"
          @search-data="searchByName"
          @delete-item="tryDelete"
          @download-item="prepareDownload"
        >
          <template v-slot:row-status="{ props }">
            <va-badge :color="getStatusColor(props.rowData.status)">
              {{ $t(getStatusLabel(props.rowData.status)) }}
            </va-badge>
          </template>
        </remote-table>
      </va-card>
    </div>
  </div>
</template>
<script>
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'preachings-index',
  components: {
    Filters, RemoteTable,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'name',
          type: 'asc',
        },
        queries: '',
        page: 1,
        perPage: 50,
      },
      filterActions: ['regions', 'countries', 'districts', 'pointsStatus'],
    }
  },
  computed: {
    tableFields () {
      return [
        {
          name: '__slot:status',
          title: this.$t('tables.headings.status'),
        },
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
          sortField: 'name',
        },
        {
          name: 'district.name',
          title: this.$t('tables.headings.district'),
          sortField: 'code',
        },
        {
          name: 'code',
          title: this.$t('tables.headings.code'),
          sortField: 'code',
        },
        {
          name: 'planted_date',
          title: this.$t('tables.headings.date'),
          sortField: 'planted_date',
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {

    getStatusColor (status) {
      let color = 'primary'
      switch (status) {
        case 0: color = 'success'; break
        case 1: color = 'danger'; break
        case 2: color = 'primary'; break
      }
      return color
    },
    getStatusLabel (status) {
      let label = 'points.status.renewed'
      switch (status) {
        case 0: label = 'points.status.open'; break
        case 1: label = 'points.status.close'; break
        case 2: label = 'points.status.graduation'; break
      }
      return label
    },

    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (Number.isInteger(filters.pointsStatus)) {
        queries += '&status=' + filters.pointsStatus
      }

      this.serverParams.queries = queries
    },
    fixData (data) {
      return data
    },
    apiUrl (params) {
      let route = 'preaching-points'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'country.name':
            field = 'Countries.name'
            break
          case 'group.name':
            field = 'Groups.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }

      if (params.queries) {
        route += params.queries
      }
      if (params.search) {
        route += '&q=' + params.search
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (this.serverParams.search !== name) {
        this.serverParams.page = 1
      }
      this.serverParams.search = name
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(this.serverParams))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }
      if (this.serverParams.search !== name) {
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('preaching-points/' + item.id)
      } catch (err) {
        // console.log('Error deleting region', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    async prepareDownload (format) {
      const prepareRoute = 'preaching-points/download'
      const downloadRoute = 'preaching-points/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        type: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        type: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        type: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        type: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
